

.input-group {
    display: flex;
  }
  
  .prefix-span {
    padding: 6px 12px;
    font-size: 14px;
    color: #555;
    background-color: #eee;
    border: 1px solid #ccc;
  }
  
  input {
    flex: 1;
    padding: 6px 12px;
    border: 1px solid #ccc;
  }
  
  .prefix input {
    border-radius: 2px 4px 4px 0px;
  }
  
  .prefix .prefix-span {
    border-right: 0;
    border-radius: 4px 0px 0px 4px;
  }
  
  /*.input-group + .input-group {
    margin-top: 10px;
  }*/