/* 
Infelizmente a biblioteca de date picker não permite alterar o tamanho
manualmente, requerendo esse fix básico
*/

.react-datepicker {
    font-size: 1em;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__header {
    padding-top: 0.8em;
}
.react-datepicker__month {
    margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}
.react-datepicker__current-month {
    font-size: 2rem;
}
.react-datepicker__day--outside-month {
    color: grey;
    font-weight: lighter;
}
.react-datepicker__navigation {
    top: 0.7em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
  }